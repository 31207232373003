import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "viewQuestionView" }

import { onMounted, ref, watchEffect } from "vue";
import message from "@arco-design/web-vue/es/message";
import CodeEditor from "@/components/CodeEditor.vue";
import MdViewer from "@/components/MdViewer.vue";
import {
  QuestionControllerService,
  QuestionSubmitAddRequest,
  QuestionVO,
} from "../../../generated";

interface Props {
  id: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewQuestionView',
  props: {
    id: { default: () => "" }
  },
  setup(__props: any) {

const props = __props;

const question = ref<QuestionVO>();

const loadData = async () => {
  const res = await QuestionControllerService.getQuestionVoByIdUsingGet(
    props.id as any
  );
  if (res.code === 0) {
    question.value = res.data;
  } else {
    message.error("加载失败，" + res.message);
  }
};

const form = ref<QuestionSubmitAddRequest>({
  language: "java",
  code: "",
});

/**
 * 提交代码
 */
const doSubmit = async () => {
  if (!question.value?.id) {
    return;
  }

  const res = await QuestionControllerService.doQuestionSubmitUsingPost({
    ...form.value,
    questionId: question.value.id,
  });
  if (res.code === 0) {
    message.success("提交成功");
  } else {
    message.error("提交失败," + res.message);
  }
};

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

const changeCode = (value: string) => {
  form.value.code = value;
};

return (_ctx: any,_cache: any) => {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_option = _resolveComponent("a-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, { gutter: [24, 24] }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          md: 12,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tabs, { "default-active-key": "question" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tab_pane, {
                  key: "question",
                  title: "题目"
                }, {
                  default: _withCtx(() => [
                    (question.value)
                      ? (_openBlock(), _createBlock(_component_a_card, {
                          key: 0,
                          title: question.value.title
                        }, {
                          extra: _withCtx(() => [
                            _createVNode(_component_a_space, { wrap: "" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.value.tags, (tag, index) => {
                                  return (_openBlock(), _createBlock(_component_a_tag, {
                                    key: index,
                                    color: "green"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(tag), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_descriptions, {
                              title: "判题条件",
                              column: { xs: 1, md: 2, lg: 3 }
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_descriptions_item, { label: "时间限制" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(question.value.judgeConfig.timeLimit ?? 0), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_descriptions_item, { label: "内存限制" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(question.value.judgeConfig.memoryLimit ?? 0), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_descriptions_item, { label: "堆栈限制" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(question.value.judgeConfig.stackLimit ?? 0), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(MdViewer, {
                              value: question.value.content || ''
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_tab_pane, {
                  key: "comment",
                  title: "评论",
                  disabled: ""
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" 评论区")
                  ])),
                  _: 1
                }),
                _createVNode(_component_a_tab_pane, {
                  key: "answer",
                  title: "答案"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" 暂时无法查看答案")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          md: 12,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              model: form.value,
              layout: "inline"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  field: "language",
                  label: "编程语言",
                  style: {"min-width":"240px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      modelValue: form.value.language,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.language) = $event)),
                      style: { width: '320px' },
                      placeholder: "选择编程语言"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_option, null, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("java")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_option, null, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode("cpp")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_option, null, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("go")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_a_option, null, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("html")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createVNode(CodeEditor, {
              value: form.value.code as string,
              language: form.value.language,
              "handle-change": changeCode
            }, null, 8, ["value", "language"]),
            _createVNode(_component_a_divider, { size: "0" }),
            _createVNode(_component_a_button, {
              type: "primary",
              style: {"min-width":"200px"},
              onClick: doSubmit
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" 提交代码 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})