import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "questionSubmitView" }

import { onMounted, ref, watchEffect } from "vue";
import {
  Question,
  QuestionControllerService,
  QuestionSubmitQueryRequest,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import moment from "moment";


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionSubmitView',
  setup(__props) {

const tableRef = ref();

const dataList = ref([]);
const total = ref(0);
const searchParams = ref<QuestionSubmitQueryRequest>({
  questionId: undefined,
  language: undefined,
  pageSize: 10,
  current: 1,
});

const loadData = async () => {
  const res = await QuestionControllerService.listQuestionSubmitByPageUsingPost(
    {
      ...searchParams.value,
      sortField: "createTime",
      sortOrder: "descend",
    }
  );
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

const columns = [
  {
    title: "提交号",
    dataIndex: "id",
  },
  {
    title: "编程语言",
    dataIndex: "language",
  },
  {
    title: "判题信息",
    slotName: "judgeInfo",
  },
  {
    title: "判题状态",
    dataIndex: "status",
  },
  {
    title: "题目 id",
    dataIndex: "questionId",
  },
  {
    title: "提交者 id",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    slotName: "createTime",
  },
];

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

const router = useRouter();

/**
 * 跳转到做题页面
 * @param question
 */
const toQuestionPage = (question: Question) => {
  router.push({
    path: `/view/question/${question.id}`,
  });
};

/**
 * 确认搜索，重新加载数据
 */
const doSubmit = () => {
  // 这里需要重置搜索页号
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_option = _resolveComponent("a-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      model: searchParams.value,
      layout: "inline"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "questionId",
          label: "题号",
          style: {"min-width":"240px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: searchParams.value.questionId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchParams.value.questionId) = $event)),
              placeholder: "请输入"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "language",
          label: "编程语言",
          style: {"min-width":"240px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              modelValue: searchParams.value.language,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchParams.value.language) = $event)),
              style: { width: '320px' },
              placeholder: "选择编程语言"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_option, null, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("java")
                  ])),
                  _: 1
                }),
                _createVNode(_component_a_option, null, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("cpp")
                  ])),
                  _: 1
                }),
                _createVNode(_component_a_option, null, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("go")
                  ])),
                  _: 1
                }),
                _createVNode(_component_a_option, null, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("html")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: doSubmit
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("搜索")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_a_divider, { size: "0" }),
    _createVNode(_component_a_table, {
      ref: tableRef.value,
      columns: columns,
      data: dataList.value,
      pagination: {
        showTotal: true,
        pageSize: searchParams.value.pageSize,
        current: searchParams.value.current,
        total: total.value,
      },
      onPageChange: onPageChange
    }, {
      judgeInfo: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(JSON.stringify(record.judgeInfo)), 1)
      ]),
      createTime: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(_unref(moment)(record.createTime).format("YYYY-MM-DD")), 1)
      ]),
      _: 1
    }, 8, ["data", "pagination"])
  ]))
}
}

})