import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "userLoginView" }

import { reactive } from "vue";
import { UserControllerService, UserLoginRequest } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

/**
 * 表单信息
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'UserLoginView',
  setup(__props) {

const form = reactive({
  userAccount: "",
  userPassword: "",
} as UserLoginRequest);

const router = useRouter();
const store = useStore();

/**
 * 提交表单
 * @param data
 */
const handleSubmit = async () => {
  const res = await UserControllerService.userLoginUsingPost(form);
  // 登录成功，跳转到主页
  if (res.code === 0) {
    await store.dispatch("user/getLoginUser");
    router.push({
      path: "/",
      replace: true,
    });
  } else {
    message.error("登陆失败，" + res.message);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h2", { style: {"margin-bottom":"16px"} }, "用户登录", -1)),
    _createVNode(_component_a_form, {
      style: {"max-width":"480px","margin":"0 auto"},
      "label-align": "left",
      "auto-label-width": "",
      model: form,
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "userAccount",
          label: "账号"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: form.userAccount,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.userAccount) = $event)),
              placeholder: "请输入账号"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "userPassword",
          tooltip: "密码不少于 8 位",
          label: "密码"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_password, {
              modelValue: form.userPassword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.userPassword) = $event)),
              placeholder: "请输入密码"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              style: {"width":"120px"}
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" 登录 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})