import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import { Editor, Viewer } from "@bytemd/vue-next";
import { ref } from "vue";

/**
 * 定义组件属性类型
 */
interface Props {
  value: string;
  mode?: string;
  handleChange: (v: string) => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MdEditor',
  props: {
    value: { default: () => "" },
    mode: { default: () => "split" },
    handleChange: { type: Function, default: (v: string) => {
    console.log(v);
  } }
  },
  setup(__props: any) {

const plugins = [
  gfm(),
  highlight(),
  // Add more plugins here
];

/**
 * 给组件指定初始值
 */
const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Editor), {
    value: _ctx.value,
    mode: _ctx.mode,
    plugins: plugins,
    onChange: _ctx.handleChange
  }, null, 8, ["value", "mode", "onChange"]))
}
}

})