import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import gfm from "@bytemd/plugin-gfm";
import highlight from "@bytemd/plugin-highlight";
import { Viewer } from "@bytemd/vue-next";


/**
 * 定义组件属性类型
 */
interface Props {
  value: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MdViewer',
  props: {
    value: { default: () => "" }
  },
  setup(__props: any) {

const plugins = [
  gfm(),
  highlight(),
  // Add more plugins here
];

/**
 * 给组件指定初始值
 */
const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Viewer), {
    value: _ctx.value,
    plugins: plugins
  }, null, 8, ["value"]))
}
}

})