import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "manageQuestionView" }

import { onMounted, ref, watchEffect } from "vue";
import {
  Page_Question_,
  Question,
  QuestionControllerService,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import * as querystring from "querystring";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'ManageQuestionView',
  setup(__props) {

const tableRef = ref();

const dataList = ref([]);
const total = ref(0);
const searchParams = ref({
  pageSize: 10,
  current: 1,
});

const loadData = async () => {
  const res = await QuestionControllerService.listQuestionByPageUsingPost(
    searchParams.value
  );
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

// {id: "1", title: "A+ D", content: "新的题目内容", tags: "["二叉树"]", answer: "新的答案", submitNum: 0,…}

const columns = [
  {
    title: "id",
    dataIndex: "id",
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "内容",
    dataIndex: "content",
  },
  {
    title: "标签",
    dataIndex: "tags",
  },
  {
    title: "答案",
    dataIndex: "answer",
  },
  {
    title: "提交数",
    dataIndex: "submitNum",
  },
  {
    title: "通过数",
    dataIndex: "acceptedNum",
  },
  {
    title: "判题配置",
    dataIndex: "judgeConfig",
  },
  {
    title: "判题用例",
    dataIndex: "judgeCase",
  },
  {
    title: "用户id",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

const doDelete = async (question: Question) => {
  const res = await QuestionControllerService.deleteQuestionUsingPost({
    id: question.id,
  });
  if (res.code === 0) {
    message.success("删除成功");
    loadData();
  } else {
    message.error("删除失败");
  }
};

const router = useRouter();

const doUpdate = (question: Question) => {
  router.push({
    path: "/update/question",
    query: {
      id: question.id,
    },
  });
};

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_table, {
      ref: tableRef.value,
      columns: columns,
      data: dataList.value,
      pagination: {
        showTotal: true,
        pageSize: searchParams.value.pageSize,
        current: searchParams.value.current,
        total: total.value,
      },
      onPageChange: onPageChange
    }, {
      optional: _withCtx(({ record }) => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: ($event: any) => (doUpdate(record))
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" 修改")
              ])),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              status: "danger",
              onClick: ($event: any) => (doDelete(record))
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("删除")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["data", "pagination"])
  ]))
}
}

})