import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "addQuestionView" }
const _hoisted_2 = { style: {"margin-top":"32px"} }

import { onMounted, ref } from "vue";
import MdEditor from "@/components/MdEditor.vue";
import { QuestionControllerService } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddQuestionView',
  setup(__props) {

const route = useRoute();
// 如果页面地址包含 update，视为更新页面
const updatePage = route.path.includes("update");

let form = ref({
  title: "",
  tags: [],
  answer: "",
  content: "",
  judgeConfig: {
    memoryLimit: 1000,
    stackLimit: 1000,
    timeLimit: 1000,
  },
  judgeCase: [
    {
      input: "",
      output: "",
    },
  ],
});

/**
 * 根据题目 id 获取老的数据
 */
const loadData = async () => {
  const id = route.query.id;
  if (!id) {
    return;
  }
  const res = await QuestionControllerService.getQuestionByIdUsingGet(
    id as any
  );
  if (res.code === 0) {
    form.value = res.data as any;
    // json 转 js 对象
    if (!form.value.judgeCase) {
      form.value.judgeCase = [
        {
          input: "",
          output: "",
        },
      ];
    } else {
      form.value.judgeCase = JSON.parse(form.value.judgeCase as any);
    }
    if (!form.value.judgeConfig) {
      form.value.judgeConfig = {
        memoryLimit: 1000,
        stackLimit: 1000,
        timeLimit: 1000,
      };
    } else {
      form.value.judgeConfig = JSON.parse(form.value.judgeConfig as any);
    }
    if (!form.value.tags) {
      form.value.tags = [];
    } else {
      form.value.tags = JSON.parse(form.value.tags as any);
    }
  } else {
    message.error("加载失败，" + res.message);
  }
};

onMounted(() => {
  loadData();
});

const doSubmit = async () => {
  console.log(form.value);
  // 区分更新还是创建
  if (updatePage) {
    const res = await QuestionControllerService.updateQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      message.success("更新成功");
    } else {
      message.error("更新失败，" + res.message);
    }
  } else {
    const res = await QuestionControllerService.addQuestionUsingPost(
      form.value
    );
    if (res.code === 0) {
      message.success("创建成功");
    } else {
      message.error("创建失败，" + res.message);
    }
  }
};

/**
 * 新增判题用例
 */
const handleAdd = () => {
  form.value.judgeCase.push({
    input: "",
    output: "",
  });
};

/**
 * 删除判题用例
 */
const handleDelete = (index: number) => {
  form.value.judgeCase.splice(index, 1);
};

const onContentChange = (value: string) => {
  form.value.content = value;
};

const onAnswerChange = (value: string) => {
  form.value.answer = value;
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_tag = _resolveComponent("a-input-tag")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("h2", null, "创建题目", -1)),
    _createVNode(_component_a_form, {
      model: _unref(form),
      "label-align": "left"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "title",
          label: "标题"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: _unref(form).title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(form).title) = $event)),
              placeholder: "请输入标题"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "tags",
          label: "标签"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_tag, {
              modelValue: _unref(form).tags,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(form).tags) = $event)),
              placeholder: "请选择标签",
              "allow-clear": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "content",
          label: "题目内容"
        }, {
          default: _withCtx(() => [
            _createVNode(MdEditor, {
              value: _unref(form).content,
              "handle-change": onContentChange
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "answer",
          label: "答案"
        }, {
          default: _withCtx(() => [
            _createVNode(MdEditor, {
              value: _unref(form).answer,
              "handle-change": onAnswerChange
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "判题配置",
          "content-flex": false,
          "merge-props": false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_space, {
              direction: "vertical",
              style: {"min-width":"480px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  field: "judgeConfig.timeLimit",
                  label: "时间限制"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      modelValue: _unref(form).judgeConfig.timeLimit,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(form).judgeConfig.timeLimit) = $event)),
                      placeholder: "请输入时间限制",
                      mode: "button",
                      min: "0",
                      size: "large"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  field: "judgeConfig.memoryLimit",
                  label: "内存限制"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      modelValue: _unref(form).judgeConfig.memoryLimit,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(form).judgeConfig.memoryLimit) = $event)),
                      placeholder: "请输入内存限制",
                      mode: "button",
                      min: "0",
                      size: "large"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  field: "judgeConfig.stackLimit",
                  label: "堆栈限制"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      modelValue: _unref(form).judgeConfig.stackLimit,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(form).judgeConfig.stackLimit) = $event)),
                      placeholder: "请输入堆栈限制",
                      mode: "button",
                      min: "0",
                      size: "large"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "测试用例配置",
          "content-flex": false,
          "merge-props": false
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(form).judgeCase, (judgeCaseItem, index) => {
              return (_openBlock(), _createBlock(_component_a_form_item, {
                key: index,
                "no-style": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_space, {
                    direction: "vertical",
                    style: {"min-width":"640px"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_component_a_form_item, {
                        field: `form.judgeCase[${index}].input`,
                        label: `输入用例-${index}`,
                        key: index
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            modelValue: judgeCaseItem.input,
                            "onUpdate:modelValue": ($event: any) => ((judgeCaseItem.input) = $event),
                            placeholder: "请输入测试输入用例"
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["field", "label"])),
                      (_openBlock(), _createBlock(_component_a_form_item, {
                        field: `form.judgeCase[${index}].output`,
                        label: `输出用例-${index}`,
                        key: index
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            modelValue: judgeCaseItem.output,
                            "onUpdate:modelValue": ($event: any) => ((judgeCaseItem.output) = $event),
                            placeholder: "请输入测试输出用例"
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["field", "label"])),
                      _createVNode(_component_a_button, {
                        status: "danger",
                        onClick: ($event: any) => (handleDelete(index))
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" 删除 ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_button, {
                onClick: handleAdd,
                type: "outline",
                status: "success"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("新增测试用例 ")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _cache[8] || (_cache[8] = _createElementVNode("div", { style: {"margin-top":"16px"} }, null, -1)),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              style: {"min-width":"200px"},
              onClick: doSubmit
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("提交 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})