import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, unref as _unref } from "vue"
import _imports_0 from '../assets/oj-logo.svg'


import { routes } from "../router/routes";
import { useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";
import ACCESS_ENUM from "@/access/accessEnum";


export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalHeader',
  setup(__props) {

const router = useRouter();
const store = useStore();

// 展示在菜单的路由数组
const visibleRoutes = computed(() => {
  return routes.filter((item, index) => {
    if (item.meta?.hideInMenu) {
      return false;
    }
    // 根据权限过滤菜单
    if (
      !checkAccess(store.state.user.loginUser, item?.meta?.access as string)
    ) {
      return false;
    }
    return true;
  });
});

// 默认主页
const selectedKeys = ref(["/"]);

// 路由跳转后，更新选中的菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

console.log();

setTimeout(() => {
  store.dispatch("user/getLoginUser", {
    userName: "管理员",
    userRole: ACCESS_ENUM.ADMIN,
  });
}, 3000);

const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};

return (_ctx: any,_cache: any) => {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    id: "globalHeader",
    align: "center",
    wrap: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, { flex: "auto" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_menu, {
            mode: "horizontal",
            "selected-keys": selectedKeys.value,
            onMenuItemClick: doMenuClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_menu_item, {
                key: "0",
                style: { padding: 0, marginRight: '38px' },
                disabled: ""
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("div", { class: "title-bar" }, [
                    _createElementVNode("img", {
                      class: "logo",
                      src: _imports_0
                    }),
                    _createElementVNode("div", { class: "title" }, "蓝月 OJ")
                  ], -1)
                ])),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleRoutes.value, (item) => {
                return (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: item.path
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["selected-keys"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { flex: "100px" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_unref(store).state.user?.loginUser?.userName ?? "未登录"), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})