import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, unref as _unref } from "vue"

const _hoisted_1 = { id: "questionsView" }

import { onMounted, ref, watchEffect } from "vue";
import {
  Page_Question_,
  Question,
  QuestionControllerService,
  QuestionQueryRequest,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import * as querystring from "querystring";
import { useRouter } from "vue-router";
import moment from "moment";


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionsView',
  setup(__props) {

const tableRef = ref();

const dataList = ref([]);
const total = ref(0);
const searchParams = ref<QuestionQueryRequest>({
  title: "",
  tags: [],
  pageSize: 8,
  current: 1,
});

const loadData = async () => {
  const res = await QuestionControllerService.listQuestionVoByPageUsingPost(
    searchParams.value
  );
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

// {id: "1", title: "A+ D", content: "新的题目内容", tags: "["二叉树"]", answer: "新的答案", submitNum: 0,…}

const columns = [
  {
    title: "题号",
    dataIndex: "id",
  },
  {
    title: "题目名称",
    dataIndex: "title",
  },
  {
    title: "标签",
    slotName: "tags",
  },
  {
    title: "通过率",
    slotName: "acceptedRate",
  },
  {
    title: "创建时间",
    slotName: "createTime",
  },
  {
    slotName: "optional",
  },
];

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

const router = useRouter();

/**
 * 跳转到做题页面
 * @param question
 */
const toQuestionPage = (question: Question) => {
  router.push({
    path: `/view/question/${question.id}`,
  });
};

/**
 * 确认搜索，重新加载数据
 */
const doSubmit = () => {
  // 这里需要重置搜索页号
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_tag = _resolveComponent("a-input-tag")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      model: searchParams.value,
      layout: "inline"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "title",
          label: "名称",
          style: {"min-width":"240px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: searchParams.value.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchParams.value.title) = $event)),
              placeholder: "请输入名称"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "tags",
          label: "标签",
          style: {"min-width":"240px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_tag, {
              modelValue: searchParams.value.tags,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchParams.value.tags) = $event)),
              placeholder: "请输入标签"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: doSubmit
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("提交")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_a_divider, { size: "0" }),
    _createVNode(_component_a_table, {
      ref: tableRef.value,
      columns: columns,
      data: dataList.value,
      pagination: {
        showTotal: true,
        pageSize: searchParams.value.pageSize,
        current: searchParams.value.current,
        total: total.value,
      },
      onPageChange: onPageChange
    }, {
      tags: _withCtx(({ record }) => [
        _createVNode(_component_a_space, { wrap: "" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.tags, (tag, index) => {
              return (_openBlock(), _createBlock(_component_a_tag, {
                key: index,
                color: "green"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(tag), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 2
        }, 1024)
      ]),
      acceptedRate: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(`${
            record.submitNum ? record.acceptedNum / record.submitNum : "0"
          }% (${record.acceptedNum}/${record.submitNum})`), 1)
      ]),
      createTime: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(_unref(moment)(record.createTime).format("YYYY-MM-DD")), 1)
      ]),
      optional: _withCtx(({ record }) => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: ($event: any) => (toQuestionPage(record))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" 做题 ")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["data", "pagination"])
  ]))
}
}

})